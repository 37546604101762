import * as React from 'react';
import { Button, Card, CardActions, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import { Image, albumUrlFromParams } from '../utility/functions';
import { useNavigate } from 'react-router-dom';

export default function Thumbnail(props: { c: number, imageUrl?: string, image?: Image, label: string }) {

    const navigate = useNavigate();

    function handleClick(folder: string): any {
        navigate(albumUrlFromParams(folder));
    }

    return (
        <>
            <Grid item key={props.c} xs={12} sm={6} md={4}>
                <Card
                    sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                >
                    <CardMedia
                        component="div"
                        sx={{
                            // 16:9
                            pt: '56.25%',
                        }}
                        image={props.imageUrl}
                    />
                    <CardContent sx={{ flexGrow: 1 }}>
                     <Typography gutterBottom variant="h5" component="h2">
                     {props.label}

                </Typography> 
                    {/* <Typography>
                    This is a media card. You can use this section to describe the
                    content.
                </Typography> */}
                    </CardContent>
                    <CardActions>
                        <Button onClick={() => handleClick(props.label)} size="small">View</Button>
                    </CardActions>
                </Card>
            </Grid>
        </>)
}