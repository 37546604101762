import * as React from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
// import Link from '@mui/material/Link';
import { Image, mapEntities, fetchEntitiesFromSubDirectories, fetchImagesRecursive } from '../utility/functions'
import Thumbnail from '../components/Thumbnail'
import { useParams } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'
import MainToolbar from '../components/MainToolbar'
import { Button } from '@mui/material'
import Footer from '../components/Footer'
import Metadata from '../components/Metadata'
import ImageModal from '../components/ImageModal'


export default function Album() {
    const params = useParams()

    const [listOfImages, setListOfImages] = useState<Image[]>([])
    const [maximizedImageIndex, setMaximizedImageIndex] = useState<number>(0)
    const [open, setOpen] = React.useState(false)
    const [metadataImageIndex, setMetadataImageIndex] = React.useState<number>()
    const folderName = params.albumName ? params.albumName + '/' : ''
    const [touchPosition, setTouchPosition] = useState<number | null>(null)

    const handleOpen = (maximizedImageIndex: number) => {
        setMaximizedImageIndex(maximizedImageIndex)
        setOpen(true)
    }
    const handleClose = () => setOpen(false)

    const handleInfoButton = (imageIndex: number) => {
        if (imageIndex > listOfImages.length || imageIndex < 0) {
            setMetadataImageIndex(undefined)
        } else {
            setMetadataImageIndex(imageIndex)
        }
    }


    const handleTouchStart = (event: React.TouchEvent<unknown>) => {
        const touchDown = event.touches[0].clientX
        setTouchPosition(touchDown)
    }

    const handleTouchEnd = (event: React.TouchEvent<unknown>) => {
        const touchDown = touchPosition

        if (touchDown === null || !listOfImages?.length) {
            return
        }
        const currentTouch = event.changedTouches[0].clientX
        const diff = touchDown - currentTouch
        let newMaximizedImageIndex = maximizedImageIndex
        if (diff > 5) {
            newMaximizedImageIndex = maximizedImageIndex < listOfImages.length - 1 ? maximizedImageIndex + 1 : maximizedImageIndex
        } else if (diff < -5) {
            newMaximizedImageIndex = maximizedImageIndex > 0 ? maximizedImageIndex - 1 : maximizedImageIndex
        } else {
            setTouchPosition(null);
            return;
        }
        handleOpen(newMaximizedImageIndex);
        setTouchPosition(null)
    }

    const downHandler = useCallback((val: { key: string }) => {
        handleClose()
        let newMaximizedImageIndex = maximizedImageIndex
        if (val.key === 'ArrowRight') {
            newMaximizedImageIndex = maximizedImageIndex < listOfImages.length - 1 ? maximizedImageIndex + 1 : maximizedImageIndex
        } else if (val.key === 'ArrowLeft') {
            newMaximizedImageIndex = maximizedImageIndex > 0 ? maximizedImageIndex - 1 : maximizedImageIndex
        } else {
            return
        }
        handleOpen(newMaximizedImageIndex)
    }, [maximizedImageIndex, listOfImages.length])

    useEffect(() => {

        fetchImagesRecursive(folderName)
            .then(async (entities) => {
                const imageEntities = await fetchEntitiesFromSubDirectories(entities)
                const { mappedImages } = mapEntities(imageEntities)

                setListOfImages(mappedImages)
            })
    }, [params, folderName])

    useEffect(() => {
        const tmp = (val: { key: string }) => downHandler(val)
        window.addEventListener('keydown', tmp)

        return () => {
            window.removeEventListener('keydown', tmp)
        }
    }, [listOfImages, downHandler])


    return (
        <div>
            <ImageModal open={open} imgPath={listOfImages[maximizedImageIndex]?.path} handleClose={handleClose}
                handleTouchStart={handleTouchStart}
                handleTouchEnd={handleTouchEnd} />
            <CssBaseline />
            <MainToolbar />
            <main>
                {/* Hero unit */}
                <Box
                    sx={{
                        bgcolor: 'background.paper',
                        pt: 8,
                        pb: 6
                    }}
                >
                    <Container maxWidth="sm">
                        <Typography
                            component="h1"
                            variant="h2"
                            align="center"
                            color="text.primary"
                        // gutterBottom
                        >
                            {params.albumName}
                        </Typography>
                        {/* <Typography variant="h5" align="center" color="text.secondary" paragraph>
              Something short and leading about the collection below—its contents,
              the creator, etc. Make it short and sweet, but not too short so folks
              don&apos;t simply skip over it entirely.
            </Typography> */}
                        {/* <Stack
              sx={{ pt: 4 }}
              direction="row"
              spacing={2}
              justifyContent="center"
            >
              <Button variant="contained">Main call to action</Button>
              <Button variant="outlined">Secondary action</Button>
            </Stack> */}
                    </Container>
                </Box>
                <Grid container spacing={2}>
                    <Grid item xs={metadataImageIndex !== undefined ? 0 : 1} md={3}>

                    </Grid>
                    <Grid item xs={metadataImageIndex !== undefined ? 8 : 10} md={6}>
                        <Grid container spacing={4}>
                            {listOfImages.map((image, index) => (
                                <Thumbnail key={image.index} c={image.index} imageUrl={image.thumbnailPath}
                                    imageIndex={index} image={image} handleOpen={handleOpen}
                                    handleInfoButton={handleInfoButton} selected={index === metadataImageIndex} />
                            ))}
                        </Grid>
                    </Grid>
                    <Grid item xs={metadataImageIndex !== undefined ? 3 : 1} md={3}>
                        {metadataImageIndex !== undefined ?
                            (
                                <div style={{ position: 'sticky', top: '2em' }}>
                                    <div>
                                        <Button variant="outlined" onClick={() => setMetadataImageIndex(undefined)} style={{ cursor: 'pointer' }}>close</Button>
                                    </div>
                                    <div style={{ marginTop: '2em' }}>
                                        <Metadata imgPath={listOfImages[metadataImageIndex].thumbnailPath}></Metadata>
                                    </div>
                                </div>
                            ) :
                            <div></div>}
                    </Grid>
                </Grid>
            </main>
            <Footer />
        </div>
    )
}
