import { Modal, styled } from '@mui/material'
import * as React from 'react'
import { Image as ImageArea } from 'mui-image'
import { TouchEventHandler } from 'react'

const StyledModal = styled(Modal)`
    z-index: 1300;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`

export default function ImageModal(props: {
    handleTouchStart: TouchEventHandler<HTMLDivElement> | undefined,
    handleTouchEnd: TouchEventHandler<HTMLDivElement> | undefined,
    open: boolean,
    imgPath?: string,
    handleClose: () => void
}) {

    const onClose = (event: any, reason: string) => {
        props.handleClose()
    }

    return (
        <StyledModal
            open={props.open}
            onClose={onClose}
            onTouchStart={props.handleTouchStart}
            onTouchEnd={props.handleTouchEnd}
            // slots={{ backdrop: StyledBackdrop }}
        >
            {(props.open && props.imgPath) ?
                <div
                    style={{
                        outline: 'none'
                    }}
                >
                    <ImageArea
                        src={props.imgPath}
                        alt="image"
                        fit="contain"
                        easing={'ease-in'}
                        duration={1000}
                        showLoading
                        style={{ maxHeight: '90vh', maxWidth: '90vw' }}
                    /></div>
                : <></>
            }
        </StyledModal>
    )
}