import { ExifData, ExifParserFactory } from "ts-exif-parser";
import { Entity, EntityType } from "../types/Entity";

export interface Image {
    name: string;
    index: number;
    path: string;
    thumbnailPath: string;
}

export const getImagesFromPublicImages = (r: any) => {
    return r.keys().map(r).map((element: string, index: number) => { return { index: index, path: element } as Image });
}

export const fetchImagesRecursive = async (directoryPath: string): Promise<Entity[]> => {
    return fetchEntities((process.env.REACT_APP_BASE_IMG_URL ?? 'https://maja.siof.pl/images/') + directoryPath)
}

export const fetchEntities = async (directoryPath: string): Promise<Entity[]> => {
    let entities = [] as Entity[];
    try {
        const response = await fetch(directoryPath);
        entities = await response.json() as Entity[] ?? [];

        entities = entities.filter(entity => {
            return !entity.name.includes("thumbnail");
        });

        entities.forEach(entity => {
            
            let thumbnailName = entity.name; 
            const extensionDotIndex = entity.name.lastIndexOf('.');
            thumbnailName = thumbnailName.substring(0, extensionDotIndex) + '_thumbnail' + thumbnailName.substring(extensionDotIndex, thumbnailName.length);
            
            entity.fullPath = `${directoryPath}/${entity.name}`;
            entity.directory = extractSubDirectoryFromFullPath(`${directoryPath}`);
            entity.thumbnailFullPath = `${directoryPath}/${thumbnailName}`;
        });
    }
    catch (ex) {
        // skip
    }
    
    return entities;
}

function extractSubDirectoryFromFullPath(fullPath: string) {
    return fullPath.replace(process.env.REACT_APP_BASE_IMG_URL?? 'https://maja.siof.pl/images/', '')
        .replaceAll('/', '')
        ;
}

export function urlFromParams(folder: string | undefined, page: number | undefined, image?: string): string {
    let urlEnding = '';
    if (folder) {
        urlEnding = urlEnding + '/folder/' + folder;
    }
    if (page) {
        urlEnding = urlEnding + '/page/' + page;
    } else if(image) {
        urlEnding = urlEnding + 'image/' + image;
    }

    return urlEnding;
}

export function albumUrlFromParams(albumName: string): string {
    let urlEnding = '';
        urlEnding = urlEnding + '/album/' + albumName;   

    return urlEnding;
}

export const fetchEntitiesFromSubDirectories = async (inputEntities: Entity[]) => {
    const imageEntities = [] as Entity[];
    const entities = inputEntities;
    let index = 0;
    while (index < entities.length) {
        if (entities[index].type === EntityType.directory) {
            entities.push(...await fetchEntities(`${entities[index].fullPath}/`
            // , entities[index].name
            ));
        } else {
            imageEntities.push(entities[index]);
        }

        ++index;
    }

    return imageEntities;
}

export const mapEntities = (entities: Entity[]) => {
    const categoriesToSet = new Map<string, Image[]>;

    const mappedImages = entities
        // .sort((a, b) => Date.parse(a.mtime) - Date.parse(b.mtime))
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((value, index) => {
            const categoryArray = categoriesToSet.get(value.directory) ?? [];
            const result = { index: index, path: value.fullPath, thumbnailPath: value.thumbnailFullPath } as Image;

            categoryArray.push(result);
            categoriesToSet.set(value.directory, categoryArray);
            return result;
        });

    return { categoriesToSet, mappedImages };
}

export const timestampToFormattedDateTime = (UNIX_timestamp: number) => {
    const a = new Date(UNIX_timestamp * 1000);
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const year = a.getFullYear();
    const month = months[a.getMonth()];
    const day = a.getDate();
    const time = day + ' ' + month + ' ' + year + ' '; // + applyTimePadding(hour) + ':' + applyTimePadding(min);
    return time;
}

const applyTimePadding = (value: number): string => {
    if (value < 10) {
        return "0" + value;
    }

    return value.toString();
}

export async function loadFile(filePath: string): Promise<ArrayBuffer> {
    const response = await fetch(filePath);
    return await response.arrayBuffer();
}

export const getExif = (fileBytes: ArrayBuffer): ExifData => {
    return ExifParserFactory.create(fileBytes).parse();
}

export const getFileDescription = (fileExif: ExifData): string | undefined => {
    return fileExif.tags?.ImageDescription;
}
export const getFileDate = (exif: ExifData): string => {
    const timestamp = exif.tags?.DateTimeOriginal
    return timestampToFormattedDateTime(timestamp ? timestamp : 1662137430);
}

export const getCamera = (exif: ExifData): string | undefined => {
    if (!exif.tags?.Make && !exif.tags?.Model) {
        return undefined;
    }
    
    return `${exif.tags?.Make ?? ''} / ${exif.tags?.Model ?? ''}`;
}

export const getKeywords = (exif: ExifData): string | undefined => {
    return String.fromCharCode.apply(null, (exif.tags?.XPKeywords as any as Array<number>)?.filter(x => x != 0));
}

