import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import HomePage from './pages/HomePage'
import NotFoundPage from './pages/NotFoundPage'
import { ROUTES } from './resources/routes-constants'
import './styles/main.sass'
import Album from './pages/Album'
import Albums from './pages/Albums'

const RootComponent: React.FC = () => {
    return (
        <Router>
            <Routes>
                <Route path="*" element={<NotFoundPage />} />
                <Route path={ROUTES.HOMEPAGE_ROUTE} element={<Albums />} />
                <Route path={ROUTES.PHOTO_ROUTE} element={<HomePage/>} />
                <Route path={ROUTES.PHOTO_FOLDER_ROUTE} element={<HomePage/>} />
                <Route path={ROUTES.PHOTO_FOLDER_AND_IMAGE_NAME_ROUTE} element={<HomePage/>} />
                <Route path={ROUTES.ALBUM} element={<Album/>} />
            </Routes>
        </Router>
    )
}

export default RootComponent
