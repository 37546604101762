import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Image, mapEntities, fetchEntitiesFromSubDirectories, fetchImagesRecursive } from '../utility/functions';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import MainToolbar from '../components/MainToolbar';
import FolderThumbnail from '../components/FolderThumbnail';
import Footer from '../components/Footer';



export default function Album() {
  const params = useParams();
  const [listOfImages, setListOfImages] = useState<Image[]>([]);
  const [categories, setCategories] = useState<Map<string, Image[]>>(new Map());

  
  useEffect(() => {
    const folderName = params.folderName ? params.folderName + "/" : '';

    fetchImagesRecursive(folderName)
      .then(async (entities) => {
        const imageEntities = await fetchEntitiesFromSubDirectories(entities);
        const { categoriesToSet, mappedImages } = mapEntities(imageEntities);

        setListOfImages(mappedImages);
        setCategories((categoriesMap) => {
          categoriesToSet.forEach((value1, key) => {
            const categoryValues = categoriesMap.get(key) ?? [];
            categoryValues.push(...value1);
            categoriesMap.set(key, categoryValues);
          });
          
          return categoriesMap;
        });
      });
    }, [params]);
    
  return (
    <div>
      <CssBaseline />
      <MainToolbar />
      <main>
        <Box
          sx={{
            bgcolor: 'background.paper',
            pt: 8,
            pb: 6,
          }}
        >
          <Container maxWidth="sm">
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="text.primary"
            // gutterBottom
            >
              {params.albumName}
            </Typography>
            {/* <Typography variant="h5" align="center" color="text.secondary" paragraph>
              Something short and leading about the collection below—its contents,
              the creator, etc. Make it short and sweet, but not too short so folks
              don&apos;t simply skip over it entirely.
            </Typography> */}
            {/* <Stack
              sx={{ pt: 4 }}
              direction="row"
              spacing={2}
              justifyContent="center"
            >
              <Button variant="contained">Main call to action</Button>
              <Button variant="outlined">Secondary action</Button>
            </Stack> */}
          </Container>
        </Box>
        <Container
          // sx={{ py: 8 }} 
          maxWidth="md">
          {/* End hero unit */}
          <Grid container spacing={4}>
            {Array.from(categories.keys()).map((category) => (
              <FolderThumbnail key={category} c={category as unknown as number} label={category}/>
            ))}
          </Grid>
        </Container>
        <Footer/>
      </main>
    </div>
  );
}
