import * as React from 'react';
import CameraIcon from '@mui/icons-material/LocationOn';

export default function Coordinates(props: { gpsLongitude?: number, gpsLatitude?: number, description?: string }) {
    let coords = <></>;

    if (props.gpsLongitude && props.gpsLatitude) {
        let locationInfo = props.description;
        if (!locationInfo) {
            locationInfo = `${props.gpsLatitude.toFixed(4)}:${props.gpsLongitude.toFixed(4)}`;
        }
        
        coords = <span style={{ wordBreak: 'break-all' }}>Location: <a href={'https://gps-coordinates.org/my-location.php?lat=' + props.gpsLatitude + '&lng=' + props.gpsLongitude} target="_blank" rel="noreferrer"><CameraIcon/>{locationInfo}</a></span>;
    } else {
        coords = <></>
    }

    return (
        <>
            {coords}
        </>
    )
}