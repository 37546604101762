import React from 'react'
import {Provider} from 'react-redux'
import {PersistGate} from 'redux-persist/integration/react'
import RootComponent from './RootComponent'
import {persistor, store} from './store/reducers/store'
import {ThemeProvider, createTheme} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#1d1f21',
      paper: '#1d1f21'
    },
    text: {
      primary: '#a2adb8'
    }, 
    divider: 'rgba(#111111, .14)'
  },
});
const App: React.FC = () => {


  return (

    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={darkTheme}>
          <CssBaseline/>
          <RootComponent/>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  )
}

export default App
