import React from 'react'
import { Chip, Stack } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { albumUrlFromParams } from '../utility/functions';

interface Image {
    index: number;
    path: string;
}

interface KeywordsProps {
    keywords: Map<string, Image[]>;
}

const Keywords: React.FC<KeywordsProps> = (props: KeywordsProps) => {

    const navigate = useNavigate();

    function handleClick(folder: string): any {
        navigate(albumUrlFromParams(folder));
    }

    return (
        <Stack direction={'row'} justifyContent={'center'} spacing={1}>
            {
                Array.from(props.keywords.entries()).map(entry => {
                    return <Chip label={entry[0]} key={entry[0]} onClick={() => handleClick(entry[0])} />;
                })
            }
        </Stack>
    )
}

export default Keywords 