export enum EntityType {
  directory = 'directory',
  file = 'file'
}
export interface Entity
{
  name: string,
  fullPath: string,
  thumbnailFullPath: string,
  type: EntityType,
  mtime: string,
  size: number,
  directory: string
}