import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CameraIcon from '@mui/icons-material/PhotoCamera';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../resources/routes-constants';

export default function MainToolbar() {

  const navigate = useNavigate();

  function handleClick(): any {
    navigate(ROUTES.HOMEPAGE_ROUTE);
  }

  return (
    <AppBar position="relative">
      <Toolbar>
        <CameraIcon sx={{ mr: 2, cursor: 'pointer' }} onClick={() => handleClick()} />
        <Typography variant="h6" color="inherit" noWrap>
          Maja
        </Typography>
      </Toolbar>
    </AppBar>
  );
}