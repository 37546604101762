import React, { useState, useEffect } from 'react'
import { getExif, getFileDate, getFileDescription, loadFile } from '../utility/functions';
import Image from 'mui-image';
import {Container} from "@mui/material";

interface PhotoPageProps {
    index: number;
    imgPath: string;
}

interface Image {
    date: string;
    description: string | undefined;
    gpsLongitude: number;
    gpsLatitude: number;
}

const PhotoPage: React.FC<PhotoPageProps> = (props: PhotoPageProps) => {
    const [image, setImage] = useState<Image>();

    useEffect(() => {
        loadFile(props.imgPath)
            .then(fileBuffer => {
                const exif = getExif(fileBuffer);
                
                setImage({
                    date: getFileDate(exif),
                    description: getFileDescription(exif) ? getFileDescription(exif) : 'Location',
                    gpsLatitude: exif.tags?.GPSLatitude ? exif.tags?.GPSLatitude : 0,
                    gpsLongitude: exif.tags?.GPSLongitude ? exif.tags?.GPSLongitude : 0
                })
            })
    }, [props]);

    let coords;

    if (image?.gpsLongitude) {
        coords = <span><a href={'https://gps-coordinates.org/my-location.php?lat=' + image.gpsLatitude + '&lng=' + image.gpsLongitude} target="_blank" rel="noreferrer">{image?.description}</a></span>;
    } else {
        coords = <></>
    }

    return (
        <Container>
            <figure>
                <Image 
                  src={props.imgPath} 
                  fit="contain" 
                  alt="image"
                  easing={"ease-in"}
                  duration={1000}
                ></Image>
                                
                <figcaption>{image?.date} <br/>
                    {coords}
                </figcaption>
            </figure>
        </Container>)
}

export default PhotoPage