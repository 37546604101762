import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import Keywords from '../components/Keywords';
import { fetchEntities, Image, mapEntities, fetchEntitiesFromSubDirectories, urlFromParams } from '../utility/functions';
import PhotoPage from './PhotoPage';
import Grid from '@mui/material/Unstable_Grid2';
import { Pagination } from "@mui/material";
import MainToolbar from '../components/MainToolbar';

const HomePage: React.FC = () => {
  const [listOfImages, setListOfImages] = useState<Image[]>([]);
  const [categories, setCategories] = useState<Map<string, Image[]>>(new Map());
  const [page, setPage] = useState(1);
  const params = useParams();
  const [touchPosition, setTouchPosition] = useState<number | null>(null)
  const navigate = useNavigate();

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    navigate(urlFromParams(params.folderName, value));
  };

  const downHandler = useCallback((val: { key: string }, page: number, pageCount: number) => {
    if (val.key === "ArrowRight") {
      const newPage = page < pageCount ? page + 1 : page;
      navigate(urlFromParams(params.folderName, newPage));
    } else if (val.key === "ArrowLeft") {
      const newPage = page > 1 ? page - 1 : page;
      navigate(urlFromParams(params.folderName, newPage));
    }
  }, [navigate, params.folderName]);

  const handleTouchStart = (event: React.TouchEvent<unknown>) => {
    const touchDown = event.touches[0].clientX
    setTouchPosition(touchDown)
  }

  const handleTouchEnd = (event: React.TouchEvent<unknown>) => {
    const touchDown = touchPosition

    if (touchDown === null || !listOfImages?.length) {
      return
    }

    const currentTouch = event.changedTouches[0].clientX
    const diff = touchDown - currentTouch

    if (diff > 5) {
      const newPage = page < listOfImages.length - 1 ? page + 1 : page;
      setPage(newPage)
    } else if (diff < -5) {
      const newPage = page > 0 ? page - 1 : page;
      setPage(newPage)
    }

    setTouchPosition(null)
  }

  useEffect(() => {
    const folderName = params.folderName ? params.folderName + "/" : '';

    fetchEntities((process.env.REACT_APP_BASE_IMG_URL ?? 'https://maja.siof.pl/images/') + folderName)
      .then(async (entities) => {
        const imageEntities = await fetchEntitiesFromSubDirectories(entities);
        const { categoriesToSet, mappedImages } = mapEntities(imageEntities);

        setListOfImages(mappedImages);
        setCategories((categoriesMap) => {
          categoriesToSet.forEach((value1, key) => {
            const categoryValues = categoriesMap.get(key) ?? [];
            categoryValues.push(...value1);
            categoriesMap.set(key, categoryValues);
          });

          return categoriesMap;
        });

        if (params.page) {
          setPage(Number(params.page));
        }
      });
  }, [params]);

  useEffect(() => {
    const tmp = (val: { key: string }) => downHandler(val, page, listOfImages.length);
    window.addEventListener("keydown", tmp);

    return () => {
      window.removeEventListener("keydown", tmp);
    }
  }, [page, listOfImages, downHandler]);

  if (!listOfImages || listOfImages.length < 1) {
    return <div>Loading...</div>
  }

  return (
    <>
      <MainToolbar />
      <Grid container spacing={2} justifyContent={'space-between'} height={'100vh'} onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>
        <Grid xs={12} alignSelf={'flex-start'}>
          <PhotoPage imgPath={listOfImages[page - 1]?.path} index={listOfImages[page - 1].index}></PhotoPage>
        </Grid>

        <Grid container alignSelf={'flex-end'} xs={12} >
          <Grid xs={12}>
            <Pagination className={'pagination'} count={listOfImages.length} variant="outlined" shape="rounded" page={page} onChange={handlePageChange} />
          </Grid>

          <Grid xs={12}>
            <Keywords keywords={categories}></Keywords>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default HomePage
