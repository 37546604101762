import { Box, Link, Typography } from '@mui/material';
import * as React from 'react';

export default function Footer() {
    function Copyright() {
        return (
            <Typography variant="body2" color="text.secondary" align="center">
                {'Copyright © '}
                <Link color="inherit" href="https://maja.siof.pl/">
                    Maja
                </Link>{' '}
                {new Date().getFullYear()}
                {'.'}
            </Typography>
        );
    }
    return (
        <Box sx={{ bgcolor: 'background.paper', p: 6 }} component="footer">
            <Typography variant="h6" align="center" gutterBottom>
                {/* Footer */}
            </Typography>
            <Typography
                variant="subtitle1"
                align="center"
                color="text.secondary"
                component="p"
            >
                {/* Something here to give the footer a purpose! */}
            </Typography>
            <Copyright />
        </Box>
    )
}