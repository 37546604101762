import React, { useEffect, useState } from "react";
import { getCamera, getExif, getFileDate, getFileDescription, loadFile } from "../utility/functions";
import Coordinates from "./Coordinates";

interface ImageMetadata {
    camera?: string;
    date: string;
    description: string | undefined;
    gpsLongitude: number;
    gpsLatitude: number;
}

export default function Metadata(props: { imgPath: string }) {

    const [image, setImage] = useState<ImageMetadata>();

    useEffect(() => {
        loadFile(props.imgPath)
            .then(fileBuffer => {
                const exif = getExif(fileBuffer);

                setImage({
                    date: getFileDate(exif),
                    description: getFileDescription(exif) ? getFileDescription(exif) : '',
                    gpsLatitude: exif.tags?.GPSLatitude ? exif.tags?.GPSLatitude : 0,
                    gpsLongitude: exif.tags?.GPSLongitude ? exif.tags?.GPSLongitude : 0,
                    camera: getCamera(exif)
                })
            })
    }, [props]);

    return (
        <>
            <div>{image?.date}</div>
            <div>
                <Coordinates gpsLatitude={image?.gpsLatitude} gpsLongitude={image?.gpsLongitude} description={image?.description}></Coordinates>
            </div>
            <div>
                {image?.camera ? 'Camera: ' + image?.camera : '' }
            </div>
        </>
    )
}