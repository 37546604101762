import * as React from 'react'
import {
    Card,
    CardActionArea,
    CardMedia,
    Grid,
    IconButton
} from '@mui/material'
import { Image } from '../utility/functions'
import InfoIcon from '@mui/icons-material/Info'

export default function Thumbnail(props: Readonly<{
    c: number,
    imageUrl: string,
    image: Image,
    imageIndex: number,
    selected?: boolean,
    handleOpen: (imageIndex: number) => void,
    handleInfoButton: (imageIndex: number) => void
}>) {

    const onInfoButtonClicked = (e: any) => {
        e.stopPropagation()
        props.handleInfoButton(props.imageIndex)
    }

    return (
        <Grid item key={props.c} xs={12} sm={6} md={4}>
            <Card
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    border: 1,
                    borderColor: props.selected ? 'primary.main' : 'transparent'
                }}
            >
                <CardActionArea>
                    <CardMedia
                        component="div"
                        sx={{
                            // 16:9
                            pt: '56.25%',
                            position: 'relative'
                        }}
                        image={props.imageUrl}
                        onClick={() => props.handleOpen(props.imageIndex)}
                    >
                        <IconButton
                            onClick={onInfoButtonClicked}
                            component="a"
                            aria-label="show information"
                            sx={{
                                position: 'absolute',
                                right: '0',
                                bottom: '0'
                            }}>
                            <InfoIcon />
                        </IconButton>

                    </CardMedia>
                </CardActionArea>
                {/* <CardContent sx={{ flexGrow: 1 }}> */}
                {/* <Typography gutterBottom variant="h5" component="h2">
                    Heading
                </Typography> */}
                {/* <Typography>
                    This is a media card. You can use this section to describe the
                    content.
                </Typography> */}
                {/* </CardContent> */}
            </Card>
        </Grid>)
}